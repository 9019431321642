export interface ISidebarItem {
    id: string;
    label: string;
    href: string;
    target: string | null;
    icon: unknown;
    permission?: string;
}

export const sidebarItems: ISidebarItem[] = [
    // {
    //     id: 'enterCode',
    //     label: 'Enter Code',
    //     href: '/',
    //     target: null,
    //     icon: '',
    // },
    // {
    //     id: 'contactlist',
    //     label: 'My Contacts',
    //     href: '/list',
    //     target: null,
    //     icon: '',
    // },
];
